.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.92);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
}

.pulse {
    margin: 100px;
    display: block;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 #de2f2f;
    animation: pulse 1.5s infinite;
}

.pulse-static {
    display: block;
    border-radius: 50%;
    padding: 15px;
    border: 14px rgba(0, 81, 255, 0.2) solid;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #de2f2f;
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 #de2f2f;
        box-shadow: 0 0 0 0 #de2f2f;
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

.overlay-route-helper {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
}

.overlay-route-medium {
    background: rgba(235, 207, 52, 0.8);
    padding: 5px;
    border-radius: 3px;
    text-shadow: 2px 1px black;
}

.overlay-route-minimum {
    background: rgba(73, 181, 27, 0.8);
    padding: 5px;
    border-radius: 3px;
    text-shadow: 2px 1px black;
}

.overlay-route-maximum {
    background: rgba(220, 24, 24, 0.8);
    padding: 5px;
    border-radius: 3px;
    text-shadow: 2px 1px black;
}

.ol-overlay-container.ol-selectable.hidden {
    display: none;
}

.ol-zoom {
    border-radius: 2px;
    display: flex;
    bottom: 1.2em !important;
    right: 7.5em !important;
    top: auto !important;
    left: auto !important;
    align-items: center;
    height: 32px;
}

.ol-zoom button.ol-zoom-in {
    background-color: #1C263B !important;
    border-radius: 2px 0px 0px 2px;
    color: white;
    margin-right: -1px !important;
    border-right: #374053 solid;
    width: 32px !important;
    height: 32px !important;
}

.ol-zoom button.ol-zoom-out {
    background-color: #1C263B !important;
    color: white;
    border-radius: 0 2px 2px 0px;
    width: 32px !important;
    height: 32px !important;
}

.ol-zoom button.ol-zoom-out:hover,
.ol-zoom button.ol-zoom-in:hover,
.ol-zoom button.ol-zoom-out:focus,
.ol-zoom button.ol-zoom-in:focus {
    color: white;
}

.rotate-north {
    top: 110px !important;
    left: 0.5em;
}
.ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.ol-control {
    background-color: transparent !important;
}

#map .ol-control .ol-zoom-in button,
#map .ol-control .ol-zoom-out button,
#map .northControl.ol-control button,
#map .gpsControl.ol-control button {
    color: white;
}

#map .northControl.ol-control button,
#map .gpsControl.ol-control button {
    background-color: unset;
    height: auto;
    width: auto;
}


@media(max-width: 640px) {
    .ol-zoom {
        left: 0.5em;
        bottom: 1em !important;
        right: 6.5em !important;
        top: auto !important;
    }

    .rotate-north {
        left: 0.5em;
        top: 14em !important;
    }
    .ol-rotate {
        bottom: 4.2em !important;
        right: 5em !important;
        z-index: 1111 !important;
    }

    .ol-control button {
        height: 1.375em !important;
        width: 1.375em !important;
        font-size: 1.14em !important;
    }

    .gpsControl {
        right: 2.0em !important;
    }

    .northControl {
        right: 4.5em !important;
    }
}

.overlay {
    border-radius: 10px 10px 10px 10px;
    min-width: 200px;
    background: #2c2c2c;
    color: white;
    z-index: 11;
}

.overlay-content {
    padding: 10px;
}

.overlay-footer {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 1rem 0px 0.5rem;
}

.overlay-cancel {
    cursor: pointer;
    color: rgb(239, 239, 239);
    box-shadow: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: none;
    border-radius: 0.2rem;
    padding: 0.45rem 0.8rem;
    margin: 0px 0.3rem;
    border-width: 0px;
    border-style: none;
    border-color: initial;
    border-image: initial;
    transition: background 0.3s ease-in-out 0s;
}

.overlay-ok {
    cursor: pointer;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: rgb(0, 169, 157);
    border-radius: 0.2rem;
    padding: 0.45rem 0.8rem;
    margin: 0px 0.3rem;
    border-width: 0px;
    border-style: none;
    border-color: initial;
    border-image: initial;
    transition: background 0.3s ease-in-out 0s;
    float: right;
}

.overlay-header {
    text-decoration: none;
    height: 22px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.overlay-header:after {
    float: right;
    cursor: pointer;
    content: '✖';
}

.cluster-overlay {
    position: absolute;
    left: -60px;
    top: 20px;
    border-radius: 0.5rem;
    /*max-height: 240px;*/
    background: #343A45;
    color: white;
    z-index: 11;
    list-style-type: none;
}

.cluster-overlay:after, .cluster-overlay:before {
    top: -30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transform: rotate(-180deg);
}
.cluster-overlay:after {
    border-top-color: #343A45;
    border-width: 16px;
    left: 51px;
    margin-left: -10px;
}

.cluster-overlay-content {
    width: 160px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    color: #00a99d;
    background: #2C303A;
    border-radius: 0 0 0.5rem 0.5rem;
}

.cluster-overlay-content li {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 10px;
    cursor: pointer;
    word-wrap: break-word;
}

.ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: '';
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}
.ol-tooltip-static:before {
    border-top-color: #ffcc33;
}

/*/ ******************************************/
.layer-switcher.shown.ol-control {
    background-color: transparent;
}

.layer-switcher.shown.ol-control:hover {
    background-color: transparent;
}

.layer-switcher {
    position: absolute;
    top: 3.5em;
    right: 0.5em;
    text-align: left;
}

.layer-switcher.shown {
    bottom: 3em;
}

.layer-switcher button {
    display: none;
}
.layer-switcher .panel {
    padding: 0 1em 0 0;
    margin: 0;
    border: 4px solid #eee;
    border-radius: 4px;
    background-color: white;
    display: none;
    max-height: 100%;
    overflow-y: auto;
}

.layer-switcher.shown .panel {
    display: block;
}

.layer-switcher button {
    float: right;
    width: 38px;
    height: 38px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==')
    /*logo.png*/;
    background-repeat: no-repeat;
    background-position: 2px;
    background-color: white;
    border: none;
}

.layer-switcher.shown button {
    display: none;
}

.layer-switcher button:focus,
.layer-switcher button:hover {
    background-color: white;
}

.layer-switcher ul {
    padding-left: 2em;
    list-style: none;
    list-style-type: none !important;
}

.layer-switcher li.group > label {
    font-weight: bold;
}

.layer-switcher li.layer {
    display: table;
}

/*.layer-switcher li.layer label, .layer-switcher li.layer input {*/
/*    display: table-cell;*/
/*    vertical-align: middle;*/
/*}*/

.layer-switcher label.disabled {
    opacity: 0.4;
}

/*.layer-switcher input {*/
/*    margin: 4px;*/
/*}*/

.layer-switcher.touch ::-webkit-scrollbar {
    width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.layer-switcher .group button {
    display: inline-block;
    vertical-align: top;
    float: none;
    width: 16px;
    height: 16px;
    background-position: center 2px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -ms-transition: -ms-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
}

.layer-switcher .group.layer-switcher-close button {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

li.group.layer-switcher-fold {
    margin-left: -18px;
}

.layer-switcher .group.layer-switcher-fold > ul {
    padding-left: 3em;
    list-style-type: none;
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
    overflow: hidden;
    height: 0;
    list-style-type: none;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 235px;
    display: none; 
}

.ol-popup:after,
.ol-popup:before {
  bottom: 100%; 
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-overlay-container.ol-selectable #popup {
    display: block;
  }
  

.toolbar-control {
    right: 3em;
    margin-left: 10px;
}


.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
}

.ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.ol-tooltip-static {
    background-color: #3D64B7 !important;
    border-radius: 94px !important;
    color: white !important;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
    background-color: #3D64B7 !important;
    border-radius: 94px !important;
    color: white !important;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}
.ol-tooltip-static:before {
    border-top-color: blue;
}

#measure-label {
    background-color: #3D64B7 !important;
    border-radius: 94px !important;
    color: white !important;
    width: 136px;
    padding-left: 8px;
    text-align: left;
    margin-left: -50px;
}

#measure-msg {
    display: none !important;
}

#map .northControl.ol-control {
    border-radius: 2px;
}

.gpsControl {
    background-color: #1C263B !important;
    bottom: 1.2em !important;
    right: 3.1em;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    border-radius: 2px;
}

.northControl {
    background-color: #1C263B !important;
    bottom: 1.2em !important;
    right: 5.3em;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
}

.ol-rotate {
    background-color: #1C263B !important;
    bottom: 1.2em !important;
    right: 5em !important;
    display: flex;
    top: auto !important;
    left: auto !important;
    width: 32px !important;
    height: 32px !important;
    align-items: center !important;
    padding-left: 4px;
    z-index: 1111;
}

.custom_scale {
    border: 2px solid #262C38 !important;
    border-top: none !important;
    height: 10px;
    right: 13em;
    bottom: 1.5em;
    width: 80px;
    position: absolute;
}

.custom_scale-inner {
    margin-left: -75%;
    color: #262C38;
    font-weight: bold;
    font-size: small;
    bottom: -0.6em;
    position: absolute;
}


.scaleUnitClass {
    bottom: 1.2em !important;
    right: 12em;
    display: flex;
    top: auto !important;
    left: auto !important;
    width: 160px !important;
    height: 16px !important;
    align-items: center !important;
    padding-left: 4px;
    background-color: rgba(255,255,255, 0.32) !important;
    z-index: -1;
    padding-top: 18px !important;
    border-radius: 0px !important;
}

.textboxarea {
    background: transparent;
    border: 0px;
    margin-bottom: -5px;
}

#printMap {
    position: absolute;
    top: -10000px;
    left: -10000px;
    cursor: all-scroll; 
    background-color: white;  
    background-image: linear-gradient(45deg, #b0b0b0 25%, transparent 25%), linear-gradient(-45deg, #b0b0b0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b0b0b0 75%), linear-gradient(-45deg, transparent 75%, #b0b0b0 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
