@import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

.dark .gantt_container {
  font-family: 'Roboto', sans-serif;
  border: none;
}
.dark .gantt_task_row {
  border-bottom: none;
}

.dark .gantt_row {
  border-bottom: none;
}
.dark .gantt_grid {
  box-shadow: 2px -5px 3px #222;
  -moz-box-shadow: 2px -5px 3px #222;
  -webkit-box-shadow: 2px -5px 3px #222;
  border: none;
  z-index: 99;
  position: relative;
}
.dark .gantt_task_cell {
  border-right: 1px solid rgba(255, 255, 255, 0.24);
}
.dark .closed_task a {
  color: gray;
  /* Inline editor styling*/
}
.dark .gantt_grid_editor_placeholder input {
  text-align: center;
  font-size: 14px;
}
.dark .gantt_grid_data .gantt_cell.gantt_last_cell {
  border-right: none;
}
.dark .gantt_resizer_x .gantt_resizer_x {
  width: 12px;
  box-shadow: 4px 0px 3px #22262d;
}

.dark .gantt_grid_scale .gantt_scale_line:first-child, .dark .gantt_task_scale .gantt_scale_line:first-child {
  font-size: 16px;
}

.dark .gantt_task_line {
  box-shadow: 0px 1.83px 1pxrgba 0, 0, 0, 0.26, 0px 0px1 0.33px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.dark .gantt_layout_root.gantt_layout_cell_border_right {
  border-right: none;
}
.dark .gantt_grid_head_cell {
  padding: 0 6px;
  font-size: 13px;
}
.dark .gantt_task_link:hover .gantt_line_wrapper div {
  box-shadow: 0 0 5px 0 #a2a2a2;
  -moz-box-shadow: 0 0 5px 0 #a2a2a2;
  -webkit-box-shadow: 0 0 5px 0 #a2a2a2;
}
.dark .gantt_task_line.gantt_selected {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: 1px solid #4BB9D9;
}
.dark .gantt_task_line.gantt_selected .gantt_line_wrapper div {
  background-color: #4BB9D9;
}
.dark .gantt_task_line.gantt_selected .gantt_task_link:hover .gantt_line_wrapper div {
  box-shadow: 0 0 5px 0 #4BB9D9;
  -moz-box-shadow: 0 0 5px 0 #4BB9D9;
  -webkit-box-shadow: 0 0 5px 0 #4BB9D9;
}
.dark .gantt_scale_line {
  border-top: none;
}
.dark .gantt_task_progress_wrapper {
  height: 5px;
  z-index: 1;
}
.dark .gantt_task_line .gantt_task_progress {
  /*background: darken($ganttBackground, 10%);*/
  border-color: #1C263B;
  background: #1C263B;
}
.dark .gantt_task_line.success .gantt_task_progress {
  background: rgba(136, 179, 66, 0.565);
}

.dark .gantt_task_line.info .gantt_task_progress {
  background: rgba(18, 169, 139, 0.565);
}

.dark .gantt_task_line.alert .gantt_task_progress {
  background: rgba(255, 161, 0, 0.565);
}

.dark .gantt_task_line.warning .gantt_task_progress {
  background: rgba(230, 81, 0, 0.565);
}

.dark .gantt_task_line.danger .gantt_task_progress {
  background: rgba(194, 37, 37, 0.565);
}
.dark .gantt_task_line.closed {
  background: rgba(128, 128, 128, 0.565);
  border-color: rgba(128, 128, 128, 0.565);
}
.dark .gantt_task_line.closed .gantt_task_progress {
  background: rgba(52, 52, 52, 0.565);
  border-right: 3px solid rgba(128, 128, 128, 0.565);
}

.dark .gantt_folder_closed, .dark .gantt_folder_open, .dark .gantt_file {
  display: none;
}

.task-template {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 4px;
  text-align: left;
  /* 1dp — Elevation */
  border-radius: 2px;
}
.task-template__avatar {
  height: 38px;
  margin-right: 8px;
}
.task-template__avatar img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 36px;
  width: 36px;
}
.task-template__avatar__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.task-template__info {
  height: 36px;
}

.task-template__info h3, .task-template__info h4 {
  margin: 0;
  line-height: 16px;
}
.task-template__info h3 {
  font-size: 14px;
}
.task-template__info h5 {
  font-size: 14px;
  margin-top: -2px;
}
.task-template__info h4 {
  font-size: 12px;
}
.grid-template {
  height: 60px;
}

.grid-template .task-template__info h3 {
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}
.date-scale {
  margin-top: 6px;
}

.date-scale.date-scale--highlight:after {
  content: '';
  position: absolute;
  top: 0;
  background: #1EA8CF;
  width: 42px;
  height: 42px;
  left: 50%;
  margin-left: -21px;
  border-radius: 50%;
  z-index: 1;
}
.date-scale__str {
  position: relative;
  z-index: 2;
  font-size: 10px;
  line-height: 12px;
}
.date-scale__num {
  position: relative;
  z-index: 2;
  line-height: 24px;
  font-size: 16px;
}
.gantt_tooltip {
  filter: invert(100%);
  font-size: 9pt;
}
i.open-task-info {
  font-size: 24px;  
}